// Constants
const MODULE = 'CBRA/USER/'; // module action prefix

const actionTypes = {
  LOGIN: `${MODULE}LOGIN_REQUEST`,
  LOGIN_SUCCESS: `${MODULE}LOGIN_SUCCESS`,
  LOGIN_FAIL: `${MODULE}LOGIN_FAIL`,
  LOGIN_CANCEL: `${MODULE}LOGIN_CANCEL`,
  LOGOUT: `${MODULE}LOGOUT_REQUEST`,
  LOGOUT_SUCCESS: `${MODULE}LOGOUT_SUCCESS`,
  LOGOUT_FAIL: `${MODULE}LOGOUT_FAIL`,
  CACHED_LOGIN: `${MODULE}CACHED_LOGIN_REQUEST`,
  CACHED_LOGIN_ACCEPTED: `${MODULE}CACHED_LOGIN_SUCCESS`,
  CACHED_LOGIN_DECLINED: `${MODULE}CACHED_LOGIN_FAIL`,
  RESET: `${MODULE}RESET`,
};

export default actionTypes;
