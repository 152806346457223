// Constants
const MODULE = 'CBRA/POKELIST/'; // module action prefix

const actionTypes = {
  FETCH_LIST: `${MODULE}LIST_REQUEST`,
  FETCH_LIST_SUCCESS: `${MODULE}LIST_SUCCESS`,
  FETCH_LIST_FAIL: `${MODULE}LIST_FAIL`,
  FETCH_LIST_CANCEL: `${MODULE}LIST_CANCEL`,
  FETCH_MON: `${MODULE}POKEMON_REQUEST`,
  FETCH_MON_SUCCESS: `${MODULE}POKEMON_SUCCESS`,
  FETCH_MON_FAIL: `${MODULE}POKEMON_FAIL`,
  FETCH_MON_CANCEL: `${MODULE}POKEMON_CANCEL`,
  SELECT_POKEMON: `${MODULE}SELECT_POKEMON`,
  RESET: `${MODULE}RESET`,
};

export default actionTypes;
